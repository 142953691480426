/* Popup.module.css */

.popup {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .button.cancel {
    background-color: #ccc;
    color: #fff;
  }
  
  .button.confirm {
    background-color: #f44336;
    color: #fff;
  }
  