$item-color: blue;
$grid-text: '';

.container {
  display: grid;
  grid-template-columns: repeat(1fr, 6);
  grid-template-rows: repeat(1fr, 6);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding:10px;
  //grid-template-areas: 'col2 col2 col3 . ''col2 col2 col3 . ''col2 col2 col3 . ''col2 col2 col1 . ''. . . . ';
  grid-template-areas: var(--grid-text);
//margin-bottom:15px;
  margin-top:12px;
  margin-left:-12px;
  margin-right:-12px;
}
[class^='col'] {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;    
}

.card {
  background: var(--back-color);
  box-shadow: 0px 0px 3px 3px gray;
  border-radius: 10px;
  padding:8px;
  //max-height: 300px;
  //width:'100%', maxHeight:'350px', height:'100%'
}

.col1 { grid-area: col1; }
.col2 { grid-area: col2; }
.col3 { grid-area: col3; }
.col4 { grid-area: col4; }
.col5 { grid-area: col5; }
.col6 { grid-area: col6; }
.col7 { grid-area: col7; }
.col8 { grid-area: col8; }
.col9 { grid-area: col9; }



// $buildCol1Color: 'white';
// $buildCol2Color: 'white';
// $buildCol3Color: 'white';
// $buildCol4Color: 'white';
// $buildCol5Color: 'white';
// $buildCol6Color: 'white';
// $buildCol7Color: 'white';
// $buildCol8Color: 'white';
// $buildCol9Color: 'white';

// .buildContainer {
//   display: grid;
//   grid-template-columns: repeat(1fr, 6);
//   grid-template-rows: repeat(1fr, 6);
//   grid-column-gap: 5px;
//   grid-row-gap: 5px;
//   grid-template-areas: var(--grid-textBuild);
// }
// [class^='buildCol'] {
//     min-height: 25px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: 0px 0px 2px 2px gray;
// }
// .card {
//    background: var(--back-color);
//    box-shadow: 0px 0px 3px 3px gray;
// }

// .buildCol1 { grid-area: col1; background-color: var(--buildCol1Color);  }
// .buildCol2 { grid-area: col2; background-color: var(--buildCol2Color) }
// .buildCol3 { grid-area: col3; background-color: var(--buildCol3Color) }
// .buildCol4 { grid-area: col4; background-color: var(--buildCol4Color) }
// .buildCol5 { grid-area: col5; background-color: var(--buildCol5Color) }
// .buildCol6 { grid-area: col6; background-color: var(--buildCol6Color) }
// .buildCol7 { grid-area: col7; background-color: var(--buildCol7Color) }
// .buildCol8 { grid-area: col8; background-color: var(--buildCol8Color) }
// .buildCol9 { grid-area: col9; background-color: var(--buildCol9Color) }
