.lot-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-list {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.marker {
  margin: 0 10px; /* Adjust the margin as needed for spacing */
}
.lot-filter-container {
  background-color: #f0f0f0; /* Replace with your desired background color */
  border-radius: 10px; /* Adjust the border-radius to control the roundness */
  padding: 10px; /* Adjust the padding as needed for spacing */
}
.recent-lots-list {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-top: 0px; /* Adjust as necessary */
  z-index: 100; /* Ensures it floats above other elements */
  min-width: 200px; /* Adjust as necessary */
  max-height: 300px; /* Adjust as necessary */
  overflow-y: auto; /* Enables scroll for long lists */
}

.recent-lots-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.recent-lots-list li {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.recent-lots-list li:hover {
  background-color: #f8f8f8;
}
