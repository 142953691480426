.react-tabs__tab--selected {
    background: blue;
    color: white;
    border-radius: 5px;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px green;
  }
  .notes-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 16px;
  }
  
  .add-note-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    max-width: 600px;
  }
  
  .note-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 8px;
  }
  
  .add-note-button {
    padding: 8px 12px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-note-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 16px;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .note-content {
    font-size: 16px;
    color: #333;
    margin-bottom: 12px;
  }
  
  .note-user, .note-date {
    font-size: 14px;
    color: #777;
  }
  
  .note-user {
    margin-bottom: 4px;
  }
/*   
  .card {
    position: relative;
    padding: 1em;
    margin: 1em 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.edit-note-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
    color: #007bff;
}

.edit-note-icon:hover {
    color: #0056b3;
}
