.edit-table {
  border-collapse: collapse;
  width: 100%;
}

.edit-table th,
.edit-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.edit-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.edit-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.edit-table tr:first-child th {
  border-top: none;
}

.edit-table tr:first-child th:first-child {
  border-left: none;
}

.edit-table tr:first-child th:last-child {
  border-right: none;
}

.edit-table td:first-child {
  border-left: none;
}

.edit-table td:last-child {
  border-right: none;
}
.divider {
  border-top: 1px solid #000;
  margin: 10px 0;
}