.table-container {
    max-height: 500px; /* Set the maximum height of the table container */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .table {
    width: 100%;
    table-layout: fixed; /* Use fixed table layout for consistent column widths */
    border-collapse: collapse;
  }
  
  .table thead th {
    background-color: #f0f0f0; /* Set the background color of table header */
    position: sticky;
    top: 0;
  }
  
  .table td,
  .table th {
    padding: 8px;
    border: 1px solid #ccc;
  }  